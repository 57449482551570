import React from 'react';
import Layout from './../layouts/nl';
import { navigate } from 'gatsby';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import OtherInvestments from '../components/OtherInvestments';
import briefcase from '../assets/images/portfolio/briefcase.png';
import mail from '../assets/images/portfolio/mail.png';
import pin from '../assets/images/portfolio/pin.png';
import phone from '../assets/images/portfolio/phone.png';
import bookmark from '../assets/images/portfolio/bookmark-icon.png';
import award from '../assets/images/portfolio/award-icon.png';
import mobileSeparator from '../assets/images/mobile-lines-background.png';
import axios from 'axios';
import noImage from '../assets/images/no-image.png'
import Scroll from './../components/Scroll';
import Spinner from './../components/Spinner';
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

export default class portfolioPage extends React.Component {

    apiEndPoint = process.env.GATSBY_API_URL;

    client = new ApolloClient({
        uri: `${process.env.GATSBY_API_URL}/graphql`,
    });

    state = {};

    responsive = {
        0: { items: 1.25 },
        768: { items: 2.25 }
      }

      stagePadding =   {
        paddingLeft: 2,     // in pixels
        paddingRight: 2
    };
  
    news = {};
  
    url = '';

    constructor(props) {
        super(props);
        const slug = props.location.search.replace('?', '');
        this.state = {
            spinnerShow: false,
            blocks: [],
            details: {},
            portfolio: null,
            related_portfolio: null,
            slug
        }
        
    }

    componentDidMount() {
        this.setState({spinnerShow: true});
        this.getPortfolio();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            slug: props.location.search.replace('?', ''),
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.getPortfolio();
        }
    }

    getPortfolio() {
      if (typeof window !== 'undefined') {
          const slug = window.location.search.replace('?', '');
          if (slug !== '') {
              this.client.query({
                  fetchPolicy: 'no-cache',
                  query: gql`
                      {
                          portfolionls(
                              where:{
                                  slug: "${slug}",
                                  active: true
                              }
                          ) {
                              id
                              client
                              business
                              aquired
                              company_name
                              address
                              phone
                              email
                              maps_pin_link
                              company_member
                              company_testimony
                              active
                              slug
                              company_related
                              company_website
                              category
                              Sold
                              created_at
                              updated_at
                              company_image {
                                  url
                              }
                              portfolio_image {
                                  url
                              }
                              company_logo {
                                  url
                              }
                          }
                      }
                  `
              }).then((response) => {
                  if (response.data && response.data.portfolionls.length > 0) {
                      const portfolio = response.data.portfolionls[0];
  
                      this.setState({
                          portfolio,
                          spinnerShow: false
                      }, () => {
                        this.loadPartially('first_section')
                            .then(() => {
                                this.loadPartially('second_section');            
                            });
                        this.loadPartially('tree').then((details) => {
                            this.setState({ blocks: details.tree });
                        });
                        this.loadRelatedCompanies();
                      });
                  }
              }).catch((err) => {
                console.log("ERR", err);
              }).finally(() => {})
          } else {
              navigate('/404')
          }
          
      }
    }
      
    loadPartially(key) {
      const { portfolio, details } = this.state;
      return new Promise((resolve, reject) => {
        this.client.query({
          fetchPolicy: 'no-cache',
          query: gql`
            {
                portfolionl(
                    id: ${portfolio.id}
                ) {
                    ${key}
                }
            }
        `
          }).then((response) => {
            const { data } = response;
            const { portfolionl } = data;
            const updatedDetails = {
              ...portfolio,
              ...details,
              ...portfolionl,
            };
            this.setState({
              details: updatedDetails,
            }, () => {
                resolve(updatedDetails)
            });
          }).catch((err) => reject(err));
      });
    }
  
    loadRelatedCompanies() {
      const { portfolio } = this.state;
      let query = gql`
      {
          portfolionls (
              where: {
                active: true
              }
            ) {
              slug
              company_name
              aquired
              company_logo {
                  url
              }
              portfolio_image {
                  url
              }
              Sold
              category
          }
      }`;
  
      if (portfolio.company_related && portfolio.company_related !== '--') {
        query = gql`
        {
            portfolionls(
                where: {
                    id: ${portfolio.company_related},
                    active: true
                }
            ) {
                slug
                company_name
                aquired
                company_logo {
                    url
                }
                portfolio_image {
                    url
                }
                Sold
                category
            }
        }`;
      }
  
      this.client.query({
        fetchPolicy: 'no-cache',
        query
      }).then(
        (response) => {
          const { portfolionls: portfolios } = response.data;
          let relatedPortfolio = null;
          if (portfolios.length === 1) {
            relatedPortfolio = portfolios[0];
          } else if (portfolios.length > 1) {
            const randomPortfolioIndex = Math.floor(Math.random() * portfolios.length)
            relatedPortfolio = portfolios[randomPortfolioIndex];
          }
  
          this.setState({
            related_portfolio: relatedPortfolio
          });
      });
    }

  bodySection() {
    let portfolio = this.state.details;
    if (!portfolio || !portfolio.first_section) {
        return false;
    }
    let companyWebsite = '';
    if (portfolio.company_website) {
        const splitLink = portfolio.company_website.split('/')
        for (let i = 0; i < splitLink.length; i++) {
            if (splitLink[i].indexOf('www') !== -1) {
                companyWebsite = splitLink[i];
            }
        }
    }

    return (
        portfolio.first_section.map((val, index) => {
            return (
                <div className="portfolio-text-wrapper" key={index}>

                    {
                        ((index % 2 === 0) ? 
                        <div id="portfolio-start" className="portfolio-about col-sm-12">
                            <div className="about-right col-sm-6">
                                {val.image && <img className="about-img" src={val.image} alt='Over'/>}
                            </div>
                            <div className="about-left col-sm-6">
                                <h4>{val.heading}</h4>
                                <p className="portfolio-text">
                                    {val.text}
                                </p>
                                <br className="portfolio-mobile-hide-break" />
                                <a href={`//${portfolio.company_website}`} target="_blank" className="more-info" rel="noopener noreferrer">Voor meer informatie, ga naar {companyWebsite}</a>
                            </div>
                        </div> : 
                        <div className="portfolio-about col-sm-12 custom-portfolio-about">
                            <div className="investment-left col-sm-6">
                                {val.image && <img className="about-img" src={val.image} alt='Investment Rational'/>}
                            </div>
                            <div className="investment-right col-sm-6">
                                <h4>{val.heading}</h4>
                                <p className="portfolio-text">
                                    {val.text} 
                                </p>
                                <br className="portfolio-mobile-hide-break" />
                                <br />
                                <Scroll type="id" element="value-create-heading">
                                    <a to="#value-create-heading" className="blue-color-link text-left">
                                        <img src={iconDownBlue} alt="" />Bekijk hoe we waarde toevoegen
                                    </a>
                                </Scroll>
                            </div>
                        </div>
                        )
                    }    
                </div>
            )
        })
    
    )
    
  }

    valueCreate() {
        let portfolio = this.state.details;
        if (!portfolio || !portfolio.second_section) {
            return null;
        }

        const secondSection = portfolio.second_section;
        const { heading, text, cards } = secondSection;

        if ((!heading || heading.length === 0) || (!text || text.length === 0)) {
            return null;
        }

        return (
            <div className="value-create col-sm-12 top-separator" id="value-create-heading">
                <div className="value-create-left col-sm-6">
                    <div className="value-create-head">
                        <h4>{portfolio.second_section.heading}</h4>
                        <p className="portfolio-text">
                            {portfolio.second_section.text}
                        </p>
                        <br />
                    </div>
                    { portfolio.second_section.cards.map((val, index) => {
                        if ((index % 2 === 0)) {
                            return
                        }
                        return (
                            <div className="value-create-box desktop-box" key={index}>
                                <h4>{val.cardHeading}</h4>
                                <p className="portfolio-text">
                                    {val.cardText}
                                </p>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="value-create-right col-sm-6">
                    { portfolio.second_section.cards.map((val, index) => {
                        if ((index % 2 === 1)) {
                            return
                        }
                        return (
                            <div className="value-create-box desktop-box" key={index}>
                                <h4>{val.cardHeading}</h4>
                                <p className="portfolio-text">
                                    {val.cardText}
                                </p>
                            </div>
                        )
                    })
                    }
                </div>
                
                <div className="value-mobile-wrapper">
                    <AliceCarousel 
                        responsive={this.responsive}
                        dotsDisabled={true}
                        buttonsDisabled={true}
                    >
                    {
                        this.items = portfolio.second_section.cards.map((val, index) =>
                            <div className="value-create-box mobile-box" key={index}>
                                <h4>{val.cardHeading}</h4>
                                <p className="portfolio-text">
                                    {val.cardText}
                                </p>
                            </div>
                        )
                    }
                    </AliceCarousel>
                </div>
            </div>
        )
    }

    mapSection() {
        let portfolio = this.state.details;
        if (!portfolio) {
            return false;
        }
        return (
            <div className="map-wrapper col-sm-12">
                <div className="map-image col-sm-6" dangerouslySetInnerHTML={{ __html: portfolio.maps_pin_link }}>
                </div>
                <div className="map-text col-sm-6 portfolio-map-text">
                    <h4 className="map-heading">Neem contact op met <br />{portfolio.company_name}</h4>
                    <div className={portfolio.company_name === null || portfolio.company_name === null ? 'map-sub hide' : 'map-sub'}>
                        <div className="map-sub-left-section">
                            <img className="map-icon" src={briefcase} alt='Briefcase'/>
                        </div>
                        <div className="map-sub-right-section">
                            <span className="map-sub-text">{portfolio.company_name}</span>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className={portfolio.address === null || portfolio.company_name === null ? 'map-sub hide' : 'map-sub'}>
                        <div className="map-sub-left-section">
                            <img className="map-icon" src={pin} alt='Pin'/>
                        </div>
                        <div className="map-sub-right-section">
                            <span className="map-sub-text">{portfolio.address}</span>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className={portfolio.phone === null || portfolio.phone === null ? 'map-sub hide' : 'map-sub'}>
                        <div className="map-sub-left-section">
                            <img className="map-icon" src={phone} alt='Phone'/>
                        </div>
                        <div className="map-sub-right-section">
                            <span className="map-sub-text">{portfolio.phone}</span>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    <div className={portfolio.email === null || portfolio.email === null ? 'map-sub hide' : 'map-sub'}>
                        <div className="map-sub-left-section">
                            <img className="map-icon" src={mail} alt='Mail'/>
                        </div>
                        <div className="map-sub-right-section">
                            <span className="map-sub-text">{portfolio.email}</span>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        )
    }

    testimonySection() {
        let portfolio = this.state.details;
        if (!portfolio) {
            return null;
        }

        if ((!portfolio.company_testimony || portfolio.company_testimony.length === 0) ||
            (!portfolio.company_member || portfolio.company_member.length === 0)) {
            return null;
        }
        
        return (
            <div className="quote-wrapper col-sm-12 section-mobile-hidden">
                <div className="col-md-12 quote-box padding-none">
                    <div className="col-md-3 padding-none">
                        <img src={(portfolio.company_image !== undefined && portfolio.company_image !== null) ? `${this.apiEndPoint}/${portfolio.company_image.url}` : noImage}  alt=''/>
                    </div>
                    <div className="col-md-9 quote-text-parrent padding-none">
                        <div className="quote-text">
                            <h4>{portfolio.company_testimony}</h4>
                            <br />
                            <span className="signature-text">{portfolio.company_member}</span>
                        </div>
                    </div>     
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    renderTreeSection() {
        const { blocks } = this.state;
        if (blocks.length === 0) {
            return null;
        }

        return (<div className="tree-wrapper col-sm-12 top-separator">
            <div className="tree-head col-sm-12">
                <h4>Holding period timeline</h4>
                <p className="portfolio-text">
                During our holding period we actively try to boost te
                </p>
                <p className="portfolio-text">
                company through research, innovation and drive
                </p>
            </div>
            {
                this.state.blocks.map(
                    (items, index) =>
                    <div className={(items.text === '' && items.heading === '') ? 'hide' : ''} key={index}>
                        <div className="full-width-tree-wrapper">
                            <div className={((index === 0 || index % 2 === 0) && ((this.state.blocks.length !== (index + 1)))) ? 'left-aligned-block' : (this.state.blocks.length !== (index + 1)) ? 'empty-block' : 'hide-block'}>
                                {
                                    (
                                        (index === 0 || index % 2 === 0) ? 
                                        <div>
                                            <div className="tree-block">
                                                <img className="bookmark-icon" src={bookmark} alt='Bookmark'/>
                                                <h4>{items.heading}</h4>
                                                <p className="portfolio-text">
                                                {items.text}
                                                </p>
                                            </div>
                                        </div> :
                                        <div></div>
                                    )
                                }
                            </div>
                            <div className={(this.state.blocks.length !== (index + 1)) ? 'tree-lines-wrapper' : 'hide-block'}>
                                <div className={(index === 0 || index % 2 === 0) ? 'tree-hand-left' : 'tree-hand-right'}>
                                    <div className="tree-hand"></div>
                                    <div className="horizontal-tree"></div>    
                                </div>
                            </div>
                            <div className={((index !== 0 && index % 2 !== 0) && ((this.state.blocks.length !== (index + 1)))) ? 'left-aligned-block' : ((this.state.blocks.length !== (index + 1)) ? 'empty-block' : 'hide-block')}>
                            {
                                    (
                                        (index !== 0 && index % 2 !== 0) ? 
                                        <div>
                                            <div className="tree-block">
                                                <img className="bookmark-icon" src={bookmark} alt='Bookmark'/>
                                                <h4>{items.heading}</h4>
                                                <p className="portfolio-text">
                                                    {items.text}
                                                </p>
                                            </div>
                                        </div> :
                                        <div></div>
                                    )
                                }
                            </div>
                            <div className={(this.state.blocks.length === (index + 1)) ? 'tree-bottom col-sm-12' : 'hide-block'}>
                                <img className="award-icon" src={award} alt='Award'/>
                                <div className="tree-box">
                                    <h4>{items.heading}</h4>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>

                        <span className="mobile-separator">
                            <img src={mobileSeparator} alt=""/>
                        </span>
                    </div>
                )
            }
        </div>);
    }

    renderSold(options) {
        if (!this.state.portfolio) {
            return null;
        }

        const { category } = this.state.portfolio;
        const {portfolio} = this.state;

        if (category !== "divested") {
            return null;
        }

        return (
            <div className="head-aquired">
                <p>Verkocht</p>
                <h4 className="first-uppercase-letter">{new Intl.DateTimeFormat('nl-NL', options).format(new Date(portfolio.Sold))}</h4>
            </div>
        );
    }

    render() {
      const { portfolio } = this.state;
      let options = {month: 'long', year: 'numeric'};
      return(
          <Layout>
              <Spinner show={this.state.spinnerShow} />
              <div id="header" className="portfolio-wrapper">
                <div className="portfolio-head col-sm-12 padding-none">
                    <div className="portfolio-left-wrapper col-sm-6 padding-none">
                    <img className="amphitec-img" src={(portfolio && portfolio.portfolio_image !== undefined && portfolio.portfolio_image !== null) ? `${this.apiEndPoint}/${portfolio.portfolio_image.url}` : noImage} alt=''/>
                        <div className="image-overlay-05-black"></div>
                        { portfolio && portfolio.company_logo && <img className="logo-img" src={`${this.apiEndPoint}/${portfolio.company_logo.url}`} alt=""/> }
                    </div>
                    <div className="portfolio-right-wrapper col-sm-6">
                        <div className="head-client">
                            <p>Bedrijf</p>
                            {portfolio && <h4>{portfolio.client}</h4>}
                        </div>
                        <div className="head-business">
                            <p>Over</p>
                            {portfolio && <h4>{portfolio.business}</h4>}
                        </div>
                        <div className="head-aquired">
                            <p>Verworven</p>
                            {portfolio && <h4 className="first-uppercase-letter">{new Intl.DateTimeFormat('en-GB', options).format(new Date(portfolio.aquired))}</h4>}
                        </div>
                        {this.renderSold(options)}
                        <div className="head-link" >
                        <Scroll type="id" element="portfolio-start">
                            <a to="#porfolio-start" className="blue-color-link text-left">
                                <img src={iconDownBlue} alt="" />Lees meer
                            </a>
                        </Scroll>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
              </div>
              <div className="content-wrapper content-portfolio-wrapper">
                  <div className="portfolio-wrapper col-sm-12 second-section-wrapper">
                      <div className="portfolio-bottom col-sm-12">
                          {this.bodySection()}
                          {this.valueCreate()}
                          {this.renderTreeSection()}
                          {this.testimonySection()}
                          {this.mapSection()}
                          <div className="narrow-wrapper col-sm-12">
                              <OtherInvestments data={this.state.related_portfolio} locale={'nl-NL'}/>
                          </div>
                          <Scroll type="id" element="header">
                              <div className="portfolio-back back-to-top-wrapper">
                                  <a href="?scroll-to=header">
                                      <i className="fas fa-angle-double-up"></i>
                                      <br />
                                      <span className="back-to-top">Back to top</span>
                                  </a>
                              </div>
                          </Scroll>
                      </div>                	
                  </div>
              </div>
          </Layout>
      )
  }
}
